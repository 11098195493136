import { useMemo } from 'react';
import { useBlockNumber } from 'wagmi';
import { calcNumBlocksUntilPriceChange } from '../util/craUtils';
import useCraAuctionInfo from './useCraAuctionInfo';

interface UsePriceChangeInfo {
  nextBlockWithPriceChange?: number;
  numBlocksUntilPriceChange?: number;
}

export default function usePriceChangeInfo(): UsePriceChangeInfo {
  const { startBlock, stepDuration } = useCraAuctionInfo();
  const { data: currentBlockNumber } = useBlockNumber({ watch: true });

  const numBlocksUntilPriceChange = calcNumBlocksUntilPriceChange(
    startBlock,
    stepDuration,
    currentBlockNumber,
  );

  const nextBlockWithPriceChange =
    currentBlockNumber && numBlocksUntilPriceChange
      ? currentBlockNumber + numBlocksUntilPriceChange
      : undefined;

  const priceChangeInfo = useMemo(
    () => ({
      numBlocksUntilPriceChange,
      nextBlockWithPriceChange,
    }),
    [numBlocksUntilPriceChange, nextBlockWithPriceChange],
  );

  return priceChangeInfo;
}
