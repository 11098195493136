import { useEffect, useState } from 'react';
import { useProvider } from 'wagmi';
import { estimateBlockEndTime } from '../../util/etherscanUtils';
import { format as formatDate } from 'date-fns';
import useCraAuctionInfo from '../../hooks/useCraAuctionInfo';

export default function CraAuctionDurationInfo() {
  const [startDate, setStartDate] = useState<Date | undefined>();
  const [endDate, setEndDate] = useState<Date | undefined>();

  const { startBlock, endBlock } = useCraAuctionInfo();

  const provider = useProvider();
  useEffect(
    function initAuctionStartDate() {
      if (!startBlock) {
        return;
      }

      if (startDate) {
        return;
      }

      async function getAuctionStartDate(startBlockNumber: number) {
        const startBlock = await provider.getBlock(startBlockNumber);

        setStartDate(new Date(startBlock.timestamp * 1000));
      }

      getAuctionStartDate(startBlock);
    },
    [startBlock, provider, startDate],
  );

  useEffect(
    function initEndDate() {
      if (!endBlock || endDate) {
        return;
      }

      async function estimateEndDate(endBlockNumber: number) {
        const estimatedTimeUntilMined = await estimateBlockEndTime(
          endBlockNumber,
        );

        if (estimatedTimeUntilMined === undefined) {
          return;
        }

        const nextBlockDate = new Date(
          new Date().getTime() + estimatedTimeUntilMined * 1000,
        );
        setEndDate(nextBlockDate);
      }

      estimateEndDate(endBlock);
    },
    [endBlock, endDate],
  );

  return (
    <div className="mt-4 uppercase">
      {endDate && (
        <div>
          <h2 className="font-light">
            {/* TODO: Add auctionEndedCheck back */}
            {/* AUCTION {!auctionEnded ? 'ENDS' : 'ENDED'}: */}
            AUCTION END:
          </h2>
          <h2 className="font-bold">{`${formatDate(
            endDate,
            "LLLL dd 'at' hh:mm a",
          )}`}</h2>
        </div>
      )}
      {startDate && (
        <div className="mt-4">
          <div>AUCTION START:</div>
          <div className="font-bold">
            {formatDate(startDate, "LLLL dd 'at' hh:mm a")}
          </div>
        </div>
      )}
    </div>
  );
}
