import { WagmiProvider, chain, createClient, defaultChains } from 'wagmi';
import { InjectedConnector } from 'wagmi/connectors/injected';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import { providers } from 'ethers';
import {
  getAlchemyId,
  getChain,
  getInfuraId,
  getRpcProvider,
  RpcProvider,
} from '../envUtil';

const appChain = getChain();

// Wallet Connect needs a full RPC URL
function formulateRpcUrl() {
  switch (getRpcProvider()) {
    case RpcProvider.Alchemy:
      return `https://eth-${appChain.name.toLowerCase()}.alchemyapi.io/v2/${getAlchemyId()}`;
    case RpcProvider.Infura:
      return `https://${appChain.name.toLowerCase()}.infura.io/v3/${getInfuraId()}`;
  }
}

function getProvider() {
  switch (getRpcProvider()) {
    case RpcProvider.Alchemy:
      return new providers.AlchemyProvider(appChain.id, getAlchemyId());
    case RpcProvider.Infura:
      return new providers.InfuraProvider(appChain.id, getInfuraId());
  }
}

function getWebSocketProvider() {
  switch (getRpcProvider()) {
    case RpcProvider.Alchemy:
      return new providers.AlchemyWebSocketProvider(
        appChain.id,
        getAlchemyId(),
      );
    case RpcProvider.Infura:
      return new providers.InfuraWebSocketProvider(appChain.id, getInfuraId());
  }
}

interface MintAppProviderProps {
  children: React.ReactNode;
}

const wagmiClient = createClient({
  connectors() {
    return [
      // https://wagmi.sh/docs/connectors/injected
      new InjectedConnector({
        chains: [appChain],
        options: { shimDisconnect: true },
      }),
      new WalletConnectConnector({
        chains: [appChain],
        options: {
          rpc: {
            [appChain.id]: formulateRpcUrl(),
          },
          qrcode: true,
        },
      }),
    ];
  },
  provider() {
    return getProvider();
  },
  webSocketProvider() {
    return getWebSocketProvider();
  },
});

export default function MintAppProvider(props: MintAppProviderProps) {
  return <WagmiProvider client={wagmiClient}>{props.children}</WagmiProvider>;
}
