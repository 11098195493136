import MintAppLayout from './MintAppLayout';
import CraMintFeed from './cra/CraMintFeed';
import CraCta from './cra/CraCta';
import CraMintInfo from './cra/CraMintInfo';
import CraInfo from './cra/CraInfo';
import CraPriceAtStep from './cra/CraPriceAtStepChart';

export default function MintCraPage() {
  return (
    <MintAppLayout>
      <div className="flex w-full flex-col items-center justify-center">
        <div className="flex w-full items-center justify-center bg-[#D1D5DB]">
          <div className="flex w-[600px] flex-row items-center justify-between space-x-10 pb-16 pt-4">
            <CraCta />
            <CraMintInfo />
          </div>
        </div>
        <div className="w-[600px]">
          <CraInfo />
          <div className="mx-auto mt-10 h-full w-full space-y-6 justify-self-stretch bg-white">
            <div>
              <CraPriceAtStep />
            </div>
            <CraMintFeed />
          </div>
        </div>
      </div>
    </MintAppLayout>
  );
}
