import { useContractRead } from 'wagmi';
import CraContractConfig from './CraContractConfig';
import { utils as ethersUtils } from 'ethers';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
} from 'chart.js';

import { Line } from 'react-chartjs-2';
import { BigNumber } from 'ethers';
import { useEffect, useMemo } from 'react';
import { THREE_MINUTES_IN_MS } from '../../util/wagmiQueryConfig';
import usePriceChangeInfo from '../../hooks/usePriceChangeInfo';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
);

export const options = {
  responsive: true,
  radius: 0,
  interaction: {
    intersect: false,
  },
  plugins: {
    title: {
      display: true,
      text: 'Price per step',
    },
  },
  parsing: {
    xAxisKey: 'step',
    yAxisKey: 'price',
  },
  scales: {
    x: {
      display: false,
    },
  },
};

export default function CraPriceAtStep() {
  const { nextBlockWithPriceChange } = usePriceChangeInfo();

  const { data: currentStep, refetch: refetchCurrentStep } = useContractRead(
    CraContractConfig,
    'getStep',
    {
      cacheTime: THREE_MINUTES_IN_MS,
      staleTime: 60_000,
      // TODO: Figure out a way to cache the "is auction ended" step
      // so that we can turn a bunch of things off.
      enabled: false,
    },
  );

  const { data: pricePerStep, refetch: refetchPricePerStep } = useContractRead(
    CraContractConfig,
    'getPricePerStep',
    {
      cacheTime: THREE_MINUTES_IN_MS,
      staleTime: 60_000,
      enabled: false,
    },
  );

  const { data: mintsPerStep, refetch: refetchMintsPerStep } = useContractRead(
    CraContractConfig,
    'getMintsPerStep',
    {
      cacheTime: THREE_MINUTES_IN_MS,
      staleTime: 60_000,
      enabled: false,
    },
  );

  useEffect(
    /**
     * The name of nextBlockWithPriceChange is not very good - we need to
     * calculate when the next price change will happen (which is, in other words,
     * which block contains the next step). We only need to fetch the step number
     * itself to calculate the tooltip below.
     *
     * TODO IND-1096: Rename nextBlockWithPriceChange to blockOfNextStep.
     */
    function refetchDataOnStepChange() {
      if (!nextBlockWithPriceChange) {
        return;
      }

      async function refetchData() {
        return Promise.all([
          refetchCurrentStep(),
          refetchMintsPerStep(),
          refetchPricePerStep(),
        ]);
      }

      refetchData();
    },
    [
      nextBlockWithPriceChange,
      refetchCurrentStep,
      refetchMintsPerStep,
      refetchPricePerStep,
    ],
  );

  const currentStepNumber = currentStep
    ? BigNumber.from(currentStep).toNumber()
    : undefined;

  const sliceStartStep =
    currentStepNumber !== undefined && pricePerStep
      ? currentStepNumber - pricePerStep.length
      : 0;

  const pricePerStepData = useMemo(
    () =>
      (pricePerStep || []).map((price, step) => ({
        step: `Step ${sliceStartStep + step}`,
        price: parseFloat(ethersUtils.formatEther(BigNumber.from(price))),
      })),
    [pricePerStep, sliceStartStep],
  );

  const mintsPerStepData = useMemo(() => {
    if (!mintsPerStep || !pricePerStep) {
      return [];
    }

    return (
      mintsPerStep
        // mintsPerStep contains more data than pricePerStep; the last
        // pricePerStep.length elements line up with pricePerStep.
        .slice(-1 * pricePerStep.length)
        .map((mints) => BigNumber.from(mints).toNumber())
    );
  }, [mintsPerStep, pricePerStep]);

  const dataset = useMemo(
    () => ({
      datasets: [
        {
          data: pricePerStepData,
          borderColor: '#0066ff',
          radius: (ctx: { dataIndex: number }) => {
            if (!!mintsPerStepData[ctx.dataIndex]) {
              return 3;
            }

            return 0;
          },
          tooltip: {
            callbacks: {
              label: (ctx: { formattedValue: string }) =>
                `${ctx.formattedValue}Ξ`,
              afterLabel: (ctx: { dataIndex: number }) => {
                const mintsLabel = `${mintsPerStepData[ctx.dataIndex]} mints`;
                if (ctx.dataIndex === pricePerStepData.length - 1) {
                  return `Latest price\n${mintsLabel}`;
                }
                return mintsLabel;
              },
            },
          },
        },
      ],
    }),
    [pricePerStepData, mintsPerStepData],
  );

  return <Line options={options} data={dataset} />;
}
