/**
 * Formats an Ethereum address so it fits more nicely in the button.
 * It will be of the form 0xnGMi..ke
 *
 * @param address - The address to format.
 * @returns - The formatted address.
 */
export function ellipsizeEthAddress(address: string): string {
  return `${address.slice(0, 6)}..${address.slice(-4)}`;
}
