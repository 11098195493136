import { BigNumber } from 'ethers';
import { useMemo } from 'react';
import { useContractRead } from 'wagmi';
import CraContractConfig from '../components/cra/CraContractConfig';
import { calcAuctionEndBlockNumber } from '../util/craUtils';
import { fetchOnceReadConfig } from '../util/wagmiQueryConfig';

interface CraAuctionInfo {
  auctionDuration?: number;
  collectionSize?: number;
  endBlock?: number;
  startBlock?: number;
  stepDuration?: number;
  isLoading: boolean;
}

type AuctionConfig = {
  isEnabled: boolean;
  startPrice: number;
  floorPrice: number;
  priceDelta: number;
  expectedStepMintRate: number;
  startBlock: number;
  stepDuration: number;
  lengthInSteps: number;
};

type TokenAllocation = {
  collectionSize: number;
  teamReserve: number;
  teamNumberMinted: number;
  auctionReserve: number;
  auctionNumberMinted: number;
};

/**
 * A typeguard used to determine whether or not a result is a Token Result.
 * @param auctionConfigResult -  The result to test.
 * @returns - Whether or not auctionConfigResult is an Token Result.
 */
function isTokenAllocation(
  tokenAllocationResult: any,
): tokenAllocationResult is TokenAllocation {
  return (
    typeof tokenAllocationResult === 'object' &&
    BigNumber.isBigNumber(tokenAllocationResult[0]) &&
    BigNumber.isBigNumber(tokenAllocationResult[1]) &&
    BigNumber.isBigNumber(tokenAllocationResult[2]) &&
    BigNumber.isBigNumber(tokenAllocationResult[3]) &&
    BigNumber.isBigNumber(tokenAllocationResult[4])
  );
}

function getTokenAllocationObject(tokenAllocationResult: any) {
  return {
    collectionSize: tokenAllocationResult[0],
    teamReserve: tokenAllocationResult[1],
    teamNumberMinted: tokenAllocationResult[2],
    auctionReserve: tokenAllocationResult[3],
    auctionNumberMinted: tokenAllocationResult[4],
  };
}

/**
 * A typeguard used to determine whether or not a result is an Auction Config.
 * @param auctionConfigResult -  The result to test.
 * @returns - Whether or not auctionConfigResult is an Auction Config.
 */
function isAuctionConfig(
  auctionConfigResult: any,
): auctionConfigResult is AuctionConfig {
  return (
    typeof auctionConfigResult === 'object' &&
    typeof auctionConfigResult[0] === 'boolean' &&
    BigNumber.isBigNumber(auctionConfigResult[1]) &&
    BigNumber.isBigNumber(auctionConfigResult[2]) &&
    BigNumber.isBigNumber(auctionConfigResult[3]) &&
    BigNumber.isBigNumber(auctionConfigResult[4]) &&
    BigNumber.isBigNumber(auctionConfigResult[5]) &&
    BigNumber.isBigNumber(auctionConfigResult[6]) &&
    BigNumber.isBigNumber(auctionConfigResult[7])
  );
}

function getAuctionConfigObject(auctionConfigResult: any) {
  return {
    isEnabled: auctionConfigResult[0],
    startPrice: auctionConfigResult[1],
    floorPrice: auctionConfigResult[2],
    priceDelta: auctionConfigResult[3],
    expectedStepMintRate: auctionConfigResult[4],
    startBlock: auctionConfigResult[5],
    stepDuration: auctionConfigResult[6],
    lengthInSteps: auctionConfigResult[7],
  };
}

/**
 * A hook used to fetch basic information about an auction.
 * This also helps us avoid common casting from an ethers.utils.Result into
 * a type that we need.
 */
export default function useCraAuctionInfo(): CraAuctionInfo {
  const { data: auctionConfigResult, isLoading: isLoadingAuctionConfig } =
    useContractRead(CraContractConfig, 'auctionConfig', fetchOnceReadConfig);

  const { data: tokenAllocationResult, isLoading: isLoadingTokenAllocation } =
    useContractRead(CraContractConfig, 'tokenAllocation', fetchOnceReadConfig);

  const auctionConfig = isAuctionConfig(auctionConfigResult)
    ? getAuctionConfigObject(auctionConfigResult)
    : undefined;

  const tokenAllocation = isTokenAllocation(tokenAllocationResult)
    ? getTokenAllocationObject(tokenAllocationResult)
    : undefined;

  const startBlock = auctionConfig
    ? BigNumber.from(auctionConfig.startBlock).toNumber()
    : undefined;

  const stepDuration = auctionConfig
    ? BigNumber.from(auctionConfig.stepDuration).toNumber()
    : undefined;

  const auctionDuration = auctionConfig
    ? BigNumber.from(auctionConfig.lengthInSteps).toNumber()
    : undefined;

  const collectionSize = tokenAllocation
    ? BigNumber.from(tokenAllocation.collectionSize).toNumber()
    : undefined;

  const endBlock = useMemo(
    () => calcAuctionEndBlockNumber(startBlock, auctionDuration, stepDuration),
    [startBlock, auctionDuration, stepDuration],
  );

  // We consider everything to be loading until we can return all the data.
  const isLoading =
    !endBlock && (isLoadingAuctionConfig || isLoadingTokenAllocation);

  const result = useMemo<CraAuctionInfo>(() => {
    return {
      auctionDuration,
      collectionSize,
      endBlock,
      startBlock,
      stepDuration,
      isLoading,
    };
  }, [
    auctionDuration,
    collectionSize,
    endBlock,
    startBlock,
    stepDuration,
    isLoading,
  ]);

  return result;
}
