import { Routes, Route } from 'react-router-dom';
import MintCraPage from './MintCraPage';

export default function MintApp() {
  return (
    <Routes>
      <Route path="/" element={<MintCraPage />}></Route>
    </Routes>
  );
}
