import axios from 'axios';
import { getChain, getEtherscanApiKey } from '../envUtil';

/**
 * As defined in https://docs.etherscan.io/api-endpoints/blocks#get-estimated-block-countdown-time-by-blockno.
 */
interface EstimateBlockTimeResponse {
  status: string;
  result: {
    EstimateTimeInSec: string;
  };
}

const AlreadyPassedResponseResult = 'Error! Block number already pass';

type EstimateBlockTimeAlreadyPassedResponse = EstimateBlockTimeResponse & {
  status: '0';
  message: 'NOTOK';
  result: typeof AlreadyPassedResponseResult;
};

function isAlreadyPassedResponse(
  o: EstimateBlockTimeResponse,
): o is EstimateBlockTimeAlreadyPassedResponse {
  if (typeof o !== 'object') {
    return false;
  }

  const potentialResponse = o as EstimateBlockTimeAlreadyPassedResponse;
  return (
    potentialResponse.status === '0' &&
    potentialResponse.message === 'NOTOK' &&
    potentialResponse.result === AlreadyPassedResponseResult
  );
}

/**
 * Estimates the amount of time, in seconds, until a block is mined.
 *
 * TODO: Handle errors.
 *
 * @param blockNumber - The block number of the block to estimate mining time
 * for.
 */
export async function estimateBlockEndTime(blockNumber: number) {
  const chain = getChain();
  const res = await axios.get<EstimateBlockTimeResponse>(
    `https://api-${chain.name}.etherscan.io/api`,
    {
      params: {
        action: 'getblockcountdown',
        apiKey: getEtherscanApiKey(),
        blockno: blockNumber,
        module: 'block',
      },
    },
  );

  if (isAlreadyPassedResponse(res.data)) {
    return undefined;
  }

  return parseInt(res.data.result.EstimateTimeInSec);
}

export function getEtherscanTxnLink(txHash: string) {
  return `https://${getChain().name}.etherscan.io/tx/${txHash}`;
}
