import { getCraContractAddress } from '../../envUtil';
import YozLabsCraAbi from '../../abis/CRA.json';

const CONTRACT_ADDRESS = getCraContractAddress();

const CraContractConfig = {
  addressOrName: CONTRACT_ADDRESS,
  contractInterface: YozLabsCraAbi.abi,
};

export default CraContractConfig;
