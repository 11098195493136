import { ReactComponent as YozLogo } from '../images/YozLogo.svg';
import { useConnect, useNetwork, chain } from 'wagmi';
import MintNavBar from './MintNavBar';
import { getChain } from '../envUtil';

interface MintAppLayoutProps {
  children: React.ReactNode;
}

const appChain = getChain();

export default function MintAppLayout(props: MintAppLayoutProps) {
  const { isConnected } = useConnect();
  const { activeChain, switchNetwork } = useNetwork();
  return (
    <>
      <div className="bg-[#D1D5DB] p-4">
        <MintNavBar />
      </div>
      {isConnected && activeChain?.id !== appChain.id && switchNetwork && (
        <div className="flex w-full flex-row justify-center space-x-2 bg-black p-5 text-white">
          <p>
            To participate in the mint, you must be connected to {appChain.name}{' '}
            Test Net.
          </p>
          <button
            className="font-bold underline"
            onClick={() => switchNetwork(appChain.id)}
          >
            Connect to {appChain.name}
          </button>
        </div>
      )}
      <div className="flex h-full w-full flex-col items-center">
        {props.children}
      </div>
    </>
  );
}
