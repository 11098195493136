import MintAppProvider from './providers/MintAppProvider';
import { BrowserRouter as Router } from 'react-router-dom';
import MintApp from './components/MintApp';
import { Toaster } from 'react-hot-toast';

global.Buffer = global.Buffer || require('buffer').Buffer;

function App() {
  return (
    <MintAppProvider>
      <Router>
        <MintApp />
      </Router>
      <Toaster
        toastOptions={{
          // Set Toast Duration to 3 Seconds
          duration: 3000,
        }}
      />
    </MintAppProvider>
  );
}

export default App;
