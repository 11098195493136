import { useEffect, useState } from 'react';
import useCraAuctionInfo from '../../hooks/useCraAuctionInfo';
import usePriceChangeInfo from '../../hooks/usePriceChangeInfo';

const COUNTER_TIME_IN_SECONDS = 15;

/**
 * A counter that shows when the next price change will occur.
 */
export default function PriceChangeCounter() {
  const [secondsUntilChangeCounter, setSecondsUntilChangeCounter] = useState<
    number | undefined
  >();
  const [showBlocks, setShowBlocks] = useState(false);

  const { stepDuration } = useCraAuctionInfo();
  const { nextBlockWithPriceChange, numBlocksUntilPriceChange } =
    usePriceChangeInfo();

  useEffect(
    function setupCounter() {
      if (!stepDuration || !nextBlockWithPriceChange) {
        return;
      }
      setSecondsUntilChangeCounter(stepDuration * COUNTER_TIME_IN_SECONDS);

      const intervalId = setInterval(
        () =>
          setSecondsUntilChangeCounter((previousSecondsLeft) => {
            if (previousSecondsLeft === undefined) {
              // This should never happen; this check is to make
              // TypeScript happy.
              return previousSecondsLeft;
            }

            if (previousSecondsLeft === 0) {
              // This keeps the counter showing 0, meaning our estimate
              // was an over-estimate.
              return 0;
            }

            return previousSecondsLeft - 1;
          }),
        1000,
      );

      return () => {
        clearInterval(intervalId);
      };
    },
    [nextBlockWithPriceChange, stepDuration],
  );

  return (
    <div
      onMouseOver={() => setShowBlocks(true)}
      onMouseOut={() => setShowBlocks(false)}
    >
      <h2 className="font-light">
        PRICE CHANGE IN:{' '}
        {secondsUntilChangeCounter !== undefined && numBlocksUntilPriceChange && (
          <span className="font-bold">
            {showBlocks ? (
              <>
                {numBlocksUntilPriceChange} block
                {numBlocksUntilPriceChange > 1 && 's'}
              </>
            ) : (
              <>
                <span className="border-b-2 border-dotted border-black">
                  ~{secondsUntilChangeCounter} seconds
                </span>
              </>
            )}
          </span>
        )}
      </h2>
    </div>
  );
}
