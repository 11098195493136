import { useEffect, useState } from 'react';
import { BigNumber, ethers, utils as ethersUtils } from 'ethers';
import {
  useContract,
  useContractEvent,
  useContractRead,
  useProvider,
  useAccount,
} from 'wagmi';
import CraContractConfig from './CraContractConfig';
import { ellipsizeEthAddress } from '../../util/ethUtils';
import { format as formatDate } from 'date-fns';
import { getEtherscanTxnLink } from '../../util/etherscanUtils';
import {
  fetchOncePerBlockConfig,
  fetchOnceReadConfig,
  THREE_MINUTES_IN_MS,
} from '../../util/wagmiQueryConfig';
import useCraAuctionInfo from '../../hooks/useCraAuctionInfo';

/**
 * A consolidated type that contains all the relevant information to
 * render a mint.
 */
export interface Mint {
  blockNumber: number;
  minter: string;
  quantity: number;
  timestamp: number;
  totalMinted: BigNumber;
  transactionHash: string;
  value: BigNumber;
}

/**
 * A more specific ethers.Event that contains fields that will be present
 * when a mint occurs.
 */
type AuctionMintEvent = ethers.Event & {
  args: {
    price: BigNumber;
    quantity: BigNumber;
    step: BigNumber;
    to: string;
    totalMinted: BigNumber;
    timestamp: BigNumber;
  };
};

/**
 * A typeguard used to determine whether or not an ethers.Event is a MintEvent.
 * @param e -  The ethers.Event to test.
 * @returns - Whether or not e is a MintEvent.
 */
function isAuctionMintEvent(e: ethers.Event): e is AuctionMintEvent {
  return (
    typeof e.args === 'object' &&
    BigNumber.isBigNumber(e.args.price) &&
    BigNumber.isBigNumber(e.args.quantity) &&
    BigNumber.isBigNumber(e.args.step) &&
    BigNumber.isBigNumber(e.args.totalMinted) &&
    BigNumber.isBigNumber(e.args.timestamp) &&
    typeof e.args.to === 'string'
  );
}

/**
 * Fetches a correctly shaped Mint for a MintEvent.
 *
 * @param event - The MintEvent to fetch a Mint for.
 * @returns - A hydrated Mint object that corresponds to the event.
 */
async function getMintForEvent(event: AuctionMintEvent): Promise<Mint> {
  return {
    blockNumber: event.blockNumber,
    minter: event.args.to,
    quantity: BigNumber.from(event.args.quantity).toNumber(),
    timestamp: BigNumber.from(event.args.timestamp).toNumber(),
    totalMinted: event.args.totalMinted,
    transactionHash: event.transactionHash,
    value: event.args.quantity.mul(event.args.price),
  };
}

export default function CraMintFeed() {
  const [mints, setMints] = useState<Mint[] | undefined>();
  const { data: accountData } = useAccount();
  const provider = useProvider();

  const { startBlock } = useCraAuctionInfo();

  const { data: totalSupply } = useContractRead(
    CraContractConfig,
    'totalSupply',
    {
      cacheTime: 15_000,
      staleTime: THREE_MINUTES_IN_MS,
      watch: false,
      enabled: false,
    },
  );

  const contractConfigWithProvider = {
    ...CraContractConfig,
    signerOrProvider: provider,
  };

  const erc721aContract: ethers.Contract = useContract(
    contractConfigWithProvider,
  );

  useContractEvent(
    contractConfigWithProvider,
    'AuctionMint',
    async ([_to, _quantity, _price, _step, _totalMinted, _timestamp, event]: [
      string,
      BigNumber,
      BigNumber,
      BigNumber,
      BigNumber,
      BigNumber,
      ethers.Event,
    ]) => {
      if (!mints) {
        return;
      }
      const potentialMintEvent = event;
      if (!isAuctionMintEvent(potentialMintEvent)) {
        return;
      }

      const newMint = await getMintForEvent(potentialMintEvent);
      const newMintTotalMinted = newMint.totalMinted.toNumber();
      if (mints[newMintTotalMinted] !== undefined) {
        return;
      }

      const mintsCopy = [...mints];
      mintsCopy[newMintTotalMinted] = newMint;

      setMints(mintsCopy);
    },
  );

  useEffect(
    function initializeMintHistoryWithStartBlock() {
      if (!startBlock) {
        // This can be undefined when the component first mounts, but also...
        // This means that the contract has been deployed, but it has not
        // been initialized with a start time.
        return;
      }

      if (mints) {
        // TODO: Figure out how to make this not fire as much.
        return;
      }

      async function initializeMintHistory() {
        const eventFilter = erc721aContract.filters.AuctionMint();
        const transferEvents = await erc721aContract.queryFilter(eventFilter);

        const historicalMints = await Promise.all(
          transferEvents
            .filter((e): e is AuctionMintEvent => isAuctionMintEvent(e))
            .map((mintEvent) => getMintForEvent(mintEvent)),
        );

        const mintIndexedArray: Mint[] = [];
        historicalMints.forEach((mintEvent) => {
          mintIndexedArray[mintEvent.totalMinted.toNumber()] = mintEvent;
        });

        setMints(mintIndexedArray);
      }

      initializeMintHistory();
    },
    [startBlock, erc721aContract, mints],
  );

  return (
    <div>
      <h2 className="mb-4 text-xl font-bold uppercase">
        LATEST MINTS | {totalSupply && BigNumber.from(totalSupply).toString()}{' '}
        MINTS TOTAL
      </h2>
      <div className="flex flex-col-reverse">
        {mints && mints.length === 0 && (
          <div>There are currently no mints.</div>
        )}
        {mints &&
          mints
            .filter((mint) => !!mint)
            .map((mint) => {
              const { minter, quantity, timestamp, totalMinted, value } = mint;
              return (
                <a
                  href={getEtherscanTxnLink(mint.transactionHash)}
                  target="_blank"
                  key={totalMinted.toNumber()}
                >
                  <div className="mb-4 flex items-center px-4 py-1 hover:bg-gray-100">
                    <div className="text-xl font-extralight">
                      <div>
                        <p
                          className={
                            accountData && accountData.address === minter
                              ? 'underline decoration-green-500/30 decoration-4 underline-offset-2'
                              : ''
                          }
                        >
                          {ellipsizeEthAddress(minter)}
                        </p>
                      </div>
                      <div className="uppercase">
                        {formatDate(
                          new Date(timestamp * 1000),
                          "LLLL dd 'at' hh:mm a",
                        )}
                      </div>
                    </div>
                    <div className="ml-auto text-2xl font-light">
                      {quantity > 1 ? `${quantity} × ` : null}
                      {`${ethersUtils.formatEther(value.div(quantity))}Ξ`}
                    </div>
                  </div>
                </a>
              );
            })}
      </div>
    </div>
  );
}
