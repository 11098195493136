/**
 * Calculates the last block of the auction.
 *
 * @param startBlock - An ethers.utils.Result containing the block number that
 * the auction started in.
 * @param auctionDuration - An ethers.utils.Result containing how many steps
 * the auction is active for.
 * @param stepDuration - An ethers.utils.Result containing how many blocks are in a step.
 *
 * @returns - A number containing the block number the auction ends in, or
 * undefined if it can't be calculated.
 */
export function calcAuctionEndBlockNumber(
  startBlock: number | undefined,
  auctionDuration: number | undefined,
  stepDuration: number | undefined,
) {
  if (!startBlock || !auctionDuration || !stepDuration) {
    return undefined;
  }

  return startBlock + auctionDuration * stepDuration;
}

/**
 * Calculates the number of blocks until a price change will occur.
 *
 * @param startBlock - An ethers.utils.Result containing the block the auction
 * started in.
 * @param auctionDuration - An ethers.utils.Result containing the stepDuration
 * @param stepDuration - An ethers.utils.Result containing the stepDuration
 * of the auction.
 * @param currentBlock - A number containing the current block number.
 *
 * @returns - A BigNumber containing the next block that a price change will
 * occur in, or undefined if it can't be calculated with the current data.
 */
export function calcNumBlocksUntilPriceChange(
  startBlock: number | undefined,
  stepDuration: number | undefined,
  currentBlock: number | undefined,
) {
  if (!startBlock || !stepDuration || !currentBlock) {
    return undefined;
  }

  const stepsElapsedInBlocks = (currentBlock - startBlock) % stepDuration;
  return stepDuration - stepsElapsedInBlocks;
}
