import { getChain } from '../../envUtil';
import BidButton from './BidButton';
import CraAuctionDurationInfo from './CraAuctionDurationInfo';
import CraTicker from './CraTicker';

const appChain = getChain();

export default function CraCta() {
  return (
    <div className="mb-auto h-full">
      <div className="font-bold uppercase">
        <h1>YOZ CONSTANT RATE AUCTION</h1>
        <h2>
          {appChain.name} {appChain.testnet && 'testnet'}
        </h2>
      </div>
      <CraTicker />
      <CraAuctionDurationInfo />
      <div className="mt-4">
        <BidButton />
      </div>
    </div>
  );
}
