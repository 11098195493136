/**
 * Component for a toast that pops up when submitting a transaction fails for a user.
 */
export default function ErrorToast() {
  return (
    <div className={`w-[600px] rounded-lg bg-[#EF4444] px-6 py-4 shadow-md`}>
      <p className="text-white">
        <span className="font-bold">Something went wrong.</span> Try again in a
        bit!
      </p>
    </div>
  );
}
