import { Link } from 'react-router-dom';
import { ReactComponent as DiscordLogo } from '../images/DiscordLogo.svg';
import { ReactComponent as TwitterLogo } from '../images/TwitterLogo.svg';
import { ReactComponent as YozLogo } from '../images/YozLogo.svg';
import WalletConnectButton from './WalletConnectButton';

export default function MintNavBar() {
  return (
    <div className="flex w-full flex-row justify-between">
      <Link to="/">
        <YozLogo />
      </Link>
      <div className="float-right my-auto ml-auto flex flex-row items-center space-x-6">
        <WalletConnectButton />
        <a
          href="https://discord.gg/ZZrmxA8UTz"
          target="_blank"
          rel="noreferrer"
        >
          <DiscordLogo className="h-6 w-6" />
        </a>
        <a href="https://twitter.com/yozlabs" target="_blank" rel="noreferrer">
          <TwitterLogo className="h-6 w-6" />
        </a>
      </div>
    </div>
  );
}
