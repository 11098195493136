/**
 * Component that holds the informative information regarding the Constant Rate Auction.
 */
export default function CraInfo() {
  return (
    <div className="mt-6">
      <h2 className="font-bold uppercase">
        What is Yoz Constant Rate Auction?
      </h2>
      <p className="mt-4">
        Simply put, our smart contract adjusts the mint price up or down based
        on demand. If demand is high, the mint price will increase at the start
        of the next time period. If demand is low, the price will go down. You
        can read more about it{' '}
        <a
          href="https://yozlabs.notion.site/Constant-Rate-Auction-CRA-8d2fa5f12ad64c858edab06d6acb2911"
          target="_blank"
          className="font-bold underline"
        >
          here
        </a>{' '}
        or{' '}
        <span className="font-bold underline">
          join our{' '}
          <a href="https://discord.gg/D2x3Uz5jew" target="_blank">
            Discord
          </a>
        </span>
        .
      </p>
    </div>
  );
}
