import { BigNumber, ethers } from 'ethers';
import {
  useContract,
  useContractRead,
  useContractWrite,
  useConnect,
  useSigner,
  useWaitForTransaction,
} from 'wagmi';
import toast from 'react-hot-toast';
import CraContractConfig from './CraContractConfig';
import ErrorToast from './Toast/ErrorToast';
import SuccessToast from './Toast/SuccessToast';
import TransactionFailedToast from './Toast/TransactionFailedToast';
import TransactionSucceedToast from './Toast/TransactionSucceedToast';
import { useEffect, useState } from 'react';

const TRANSACTION_SUCCESS = 1;
const TRANSACTION_FAILURE = 0;

function getBidButtonText(
  isConnected: boolean,
  isSendingMintTransaction: boolean,
  isWaitingForMint: boolean,
) {
  if (!isConnected) {
    return 'not connected';
  }

  if (isSendingMintTransaction || isWaitingForMint) {
    return 'minting';
  }

  return 'mint';
}

/**
 * A button used to bid on an NZA. If the user isn't logged in, it prompts
 * them to connect.
 */
export default function BidButton() {
  const [quantityToMint, setQuantityToMint] = useState(1);

  const {
    connectAsync: connectWallet,
    connectors,
    isConnected,
    isConnecting,
  } = useConnect();

  const { data: signer } = useSigner();
  const contractConfigWithSigner = {
    ...CraContractConfig,
    signerOrProvider: signer || undefined,
  };

  const erc721aContract: ethers.Contract = useContract(
    contractConfigWithSigner,
  );

  const { data: currentAuctionPrice } = useContractRead(
    CraContractConfig,
    'getCurrentAuctionPrice',
    // TODO: Only fetch data as a mint happens, instead of constantly reading it
    { staleTime: Infinity, watch: false },
  );

  const {
    data: mintTransactionResult,
    writeAsync: mintFromContract,
    isLoading: isSendingMintTransaction,
  } = useContractWrite(contractConfigWithSigner, 'auctionMint');

  const mintTransactionHash = mintTransactionResult?.hash;

  const { isLoading: isWaitingForMint, data: mintTxnData } =
    useWaitForTransaction({
      hash: mintTransactionHash,
      confirmations: 1,
    });

  async function mint(numNfts: number) {
    if (!currentAuctionPrice) {
      throw new Error('Could not fetch current auction price when bidding');
    }

    const value = currentAuctionPrice.mul(numNfts);

    const estimate = await erc721aContract.estimateGas.auctionMint(numNfts, {
      value,
    });

    return await mintFromContract({
      args: numNfts,
      overrides: {
        value,
        gasLimit: BigNumber.from(estimate.mul(2)),
      },
    });
  }

  useEffect(() => {
    if (!mintTransactionHash || !mintTxnData) {
      return;
    }

    if (isWaitingForMint) {
      return;
    }

    if (mintTxnData.status === TRANSACTION_SUCCESS) {
      toast.custom(<TransactionSucceedToast txnHash={mintTransactionHash} />);
      return;
    }

    if (mintTxnData.status === TRANSACTION_FAILURE) {
      toast.custom(<TransactionFailedToast txnHash={mintTransactionHash} />);
      return;
    }
  }, [isWaitingForMint, mintTransactionHash, mintTxnData]);

  return (
    <div>
      <div className="mb-2">
        <label
          htmlFor="quantity"
          className="block text-sm font-medium text-gray-700"
        >
          Quantity
        </label>
        <select
          id="quantity"
          name="quantity"
          className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base  focus:outline-none sm:text-sm"
          disabled={!isConnected}
          value={quantityToMint}
          onChange={(event) => {
            setQuantityToMint(parseInt(event.target.value));
          }}
        >
          <option>1</option>
          <option>2</option>
          <option>3</option>
        </select>
      </div>
      <button
        className="text-md inline-flex w-full items-center justify-center rounded-[4px] border border-transparent bg-connect-black px-4 py-2 text-center font-bold uppercase text-white shadow-sm hover:bg-gray-600 focus:outline-none disabled:opacity-50"
        disabled={
          !isConnected ||
          isConnecting ||
          isSendingMintTransaction ||
          isWaitingForMint
        }
        onClick={async () => {
          try {
            const res = await mint(quantityToMint);
            toast.custom(<SuccessToast txnHash={res.hash} />);
          } catch (error: any) {
            // If a user rejects, don't pop a toast.
            // Ideally, we would want to use the name of the URRE object from Wagmi,
            // But this will suffice for now since that object is coming back with "n" as the name.
            if (error.name === 'UserRejectedRequestError') {
              return;
            }

            toast.custom(<ErrorToast />);
          }
        }}
      >
        {getBidButtonText(
          isConnected,
          isSendingMintTransaction,
          isWaitingForMint,
        )}
      </button>
    </div>
  );
}
