import { ChainName } from '@wagmi/core/dist/declarations/src/constants/chains';
import { chain } from 'wagmi';

/**
 * An enum representing the RPC provider the app is using for RPC calls.
 */
export enum RpcProvider {
  Alchemy = 'alchemy',
  Infura = 'infura',
}

/**
 * Gets the ERC721A contract address specified in .env.

 * @returns - A string containing the contract address of the ERC721A
 * contract.
 */
export function getErc721aContractAddress() {
  const contractAddress = process.env.REACT_APP_ERC721A_CONTRACT_ADDRESS;
  if (!contractAddress) {
    throw new Error('Missing REACT_APP_ERC721A_CONTRACT_ADDRESS in .env');
  }

  return contractAddress;
}

export function getChain() {
  const chainName = process.env.REACT_APP_CHAIN;
  if (!chainName) {
    throw new Error('Missing REACT_APP_CHAIN in .env');
  }

  const chainConfig = chain[chainName as ChainName];
  if (!chainConfig) {
    throw new Error(`Invalid chain ${chainName}`);
  }

  return chainConfig;
}

/**
 * Gets the Infura ID for Yoz's Infura RPC account.
 *
 * @returns - A string containing our Infura ID.
 */
export function getInfuraId() {
  const infuraId = process.env.REACT_APP_INFURA_ID;
  if (!infuraId) {
    throw new Error('Missing REACT_APP_INFURA_ID in .env');
  }

  return infuraId;
}

/**
 * Gets the Alchemy ID for Yoz's Infura RPC account.
 *
 * @returns - A string containing our Alchemy ID.
 */
export function getAlchemyId() {
  const alchemyId = process.env.REACT_APP_ALCHEMY_ID;
  if (!alchemyId) {
    throw new Error('Missing REACT_APP_ALCHEMY_ID in .env');
  }

  return alchemyId;
}

export function getRpcProvider() {
  const rpcProvider = process.env.REACT_APP_RPC_PROVIDER;
  if (!rpcProvider) {
    throw new Error('Missing REACT_APP_RPC_PROVIDER In .env');
  }

  if (
    rpcProvider !== RpcProvider.Alchemy &&
    rpcProvider !== RpcProvider.Infura
  ) {
    throw new Error(`Unknown RPC provider ${rpcProvider}`);
  }

  return rpcProvider as RpcProvider;
}

/**
 * Gets the contract address for CRA to display.
 *
 * @returns - A string containing the contract address of the CRA contract.
 */
export function getCraContractAddress() {
  const contractAddress = process.env.REACT_APP_CRA_CONTRACT_ADDRESS;
  if (!contractAddress) {
    throw new Error('Missing REACT_APP_CRA_CONTRACT_ADDRESS in .env');
  }

  return contractAddress;
}

/**
 * Gets the Etherscan API key used for API requests.
 *
 * @returns - A string containing the Etherscan API key.
 */
export function getEtherscanApiKey() {
  const apiKey = process.env.REACT_APP_ETHERSCAN_API_KEY;
  if (!apiKey) {
    throw new Error('Missing REACT_APP_ETHERSCAN_API_KEY in .env');
  }

  return apiKey;
}
