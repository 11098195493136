import { utils as ethersUtils } from 'ethers';
import { useEffect } from 'react';
import { useBlockNumber, useContractRead } from 'wagmi';
import useCraAuctionInfo from '../../hooks/useCraAuctionInfo';
import usePriceChangeInfo from '../../hooks/usePriceChangeInfo';
import { THREE_MINUTES_IN_MS } from '../../util/wagmiQueryConfig';
import CraContractConfig from './CraContractConfig';
import PriceChangeCounter from './PriceChangeCounter';

export default function CraTicker() {
  const { collectionSize, endBlock } = useCraAuctionInfo();

  const { data: currentBlockNumber } = useBlockNumber({
    staleTime: 30_000,
    watch: true,
  });

  const { nextBlockWithPriceChange } = usePriceChangeInfo();

  const { data: currentAuctionPrice, refetch: refetchCurrentAuctionPrice } =
    useContractRead(CraContractConfig, 'getCurrentAuctionPrice', {
      cacheTime: 60_000,
      staleTime: 90_000,
      watch: false,
      enabled: false,
    });

  useEffect(
    function updatePriceEveryStep() {
      if (!nextBlockWithPriceChange) {
        return;
      }

      refetchCurrentAuctionPrice();
    },
    [nextBlockWithPriceChange, refetchCurrentAuctionPrice],
  );

  const { data: totalSupply, refetch: refetchTotalSupply } = useContractRead(
    CraContractConfig,
    'totalSupply',
    {
      cacheTime: 15_000,
      staleTime: THREE_MINUTES_IN_MS,
      watch: false,
      enabled: false,
    },
  );

  useEffect(
    function updateSupplyEveryBlock() {
      if (!currentBlockNumber) {
        return;
      }

      refetchTotalSupply();
    },
    [currentBlockNumber, refetchTotalSupply],
  );

  const isAuctionActive =
    currentBlockNumber && endBlock && currentBlockNumber < endBlock;

  return (
    <div className="mt-4 flex flex-col">
      <h2 className="font-light">
        CURRENT MINT PRICE:
        {currentAuctionPrice && (
          <span className="font-bold">
            {' '}
            {ethersUtils.formatEther(currentAuctionPrice).toString()}Ξ
          </span>
        )}
      </h2>
      {totalSupply && collectionSize && (
        <div className="flex h-full w-full text-right font-light uppercase">
          <h2>
            Minted:{' '}
            {totalSupply && collectionSize && (
              <span className="font-bold">
                {totalSupply.toString()} of {collectionSize}
              </span>
            )}
          </h2>
        </div>
      )}
      {isAuctionActive && <PriceChangeCounter />}
    </div>
  );
}
