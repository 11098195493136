import { ellipsizeEthAddress } from '../../../util/ethUtils';
import { getEtherscanTxnLink } from '../../../util/etherscanUtils';

interface TransactionFailedToastProps {
  txnHash: string;
}

/**
 * Component for a toast that pops up when the mint transaction for a user fails.
 */
export default function TransactionFailedToast(
  props: TransactionFailedToastProps,
) {
  const { txnHash } = props;

  return (
    <div className={`w-[600px] rounded-lg bg-[#EF4444] px-6 py-4 shadow-md`}>
      <p className="text-white">
        Transaction{' '}
        <a
          href={getEtherscanTxnLink(txnHash)}
          target="_blank"
          className="font-bold underline"
        >
          {ellipsizeEthAddress(txnHash)}
        </a>{' '}
        failed.
      </p>
    </div>
  );
}
