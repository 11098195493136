import { ellipsizeEthAddress } from '../../../util/ethUtils';
import { getEtherscanTxnLink } from '../../../util/etherscanUtils';

interface SuccessToastProps {
  txnHash: string;
}

/**
 * Component for a toast that pops up when submitting a transaction succeeds for a user.
 *
 */
export default function SuccessToast(props: SuccessToastProps) {
  const { txnHash } = props;

  return (
    <div className={`w-[600px] rounded-lg bg-[#34D399] px-6 py-4 shadow-md`}>
      <p className="text-white">
        <span className="font-bold">Success!</span> Submitted transaction{' '}
        <a
          href={getEtherscanTxnLink(txnHash)}
          target="_blank"
          className="font-bold underline"
        >
          {ellipsizeEthAddress(txnHash)}
        </a>{' '}
      </p>
    </div>
  );
}
